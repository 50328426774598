import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import QrPage from './pages/QrPage';
import RedirectPage from './pages/RedirectPage';
import NavigationBar from './components/NavigationBar';
import ReactGA from "react-ga4";





function App() {
  const apiBaseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : 'https://api.url.meesvanstraten.nl';

    const frontendBaseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://url.meesvanstraten.nl';

    console.log("api url: ", apiBaseUrl)
    console.log("NODE_ENV", process.env.NODE_ENV)

    const TRACKING_ID = "G-M6BLJG2GYP";
    ReactGA.initialize(TRACKING_ID);

useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search), title: "Pageview title" });
  }, []);


  return (
  <Router>
        <div className="App">
          <header className="App-header">
            <NavigationBar />
            <Routes>
              <Route path="/" element={<HomePage frontendBaseUrl={frontendBaseUrl} apiBaseUrl={apiBaseUrl} />} />
              <Route path="/url" element={<RedirectPage frontendBaseUrl={frontendBaseUrl} apiBaseUrl={apiBaseUrl} />} />
              <Route path="/url/:shortUrlId" element={<RedirectPage frontendBaseUrl={frontendBaseUrl} apiBaseUrl={apiBaseUrl} />} />
              <Route path="/qr" element={<QrPage apiBaseUrl={apiBaseUrl} />} />
            </Routes>
          </header>
        </div>
      </Router>


  );
}

export default App;
