import React, { useState } from 'react';
import '../App.css';
import useAnalyticsEventTracker from '../analytics/useAnalyticsEventTracker';

const HomePage = ({ frontendBaseUrl, apiBaseUrl }) => {
  const [inputValue, setInputValue] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  document.title = "URL shortener";

  const gaEventTracker = useAnalyticsEventTracker('URL page');

  const isValidUrl = (url) => {
    // Regular expression to check if the URL has a valid format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    // Check if the URL is not empty and matches the valid format
    // Also check for URLs without explicit protocol (e.g., google.com or www.google.com)
    // and ensure that domain has at least one dot
    return url && (
      urlPattern.test(url) ||
      (/^[^ "]+\.[^ "]+(\.[^ "]+)*$/.test(url) && url.indexOf('.') !== -1)
    );
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (!isValidUrl(inputValue)) {
      // If the URL is not valid, set an error message
      setErrorMessage('Invalid URL');
      return;
    }

    const postData = {
      original_url: inputValue
    };

    fetch(`${apiBaseUrl}/url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        setResponseData(data);
        gaEventTracker('url-shorten-success')
        // Clear error message if any
        setErrorMessage('');
      })
      .catch(error => {
        console.error('Error:', error);
        gaEventTracker('url-shorten-error')
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>URL Shortener</h1>
        <div className="centered">
          <input
            className="text-field"
            type="text"
            placeholder="Your long url"
            value={inputValue}
            onChange={handleInputChange}
          />
          <button className="button" onClick={handleButtonClick}>Shorten link</button>
        </div>
        {errorMessage && (
          <p className="error-message">{errorMessage}</p>
        )}
        {responseData && (
          <div>
            <p>Original URL: {responseData.original_url}</p>
            <p>
              Shortened URL:{' '}
              <a href={`${frontendBaseUrl}/url/${responseData.shortened_url}`}>
                {`${frontendBaseUrl}/url/${responseData.shortened_url}`}
              </a>
            </p>
            <p>Link clicked: {responseData.click_count} times</p>
          </div>
        )}
      </header>
    </div>
  );
};

export default HomePage;
