import React, { useState, useEffect } from 'react';
import useAnalyticsEventTracker from '../analytics/useAnalyticsEventTracker';

function RedirectPage({ frontendBaseUrl,apiBaseUrl }) {
  const [originalUrl, setOriginalUrl] = useState('');
  const [urlId, setUrlId] = useState('')
  const [error, setError] = useState('');

  const gaEventTracker = useAnalyticsEventTracker('QR page');

    const sendClickEvent = async () => {
              try {
                const response = await fetch(`${apiBaseUrl}/url/click/${urlId}`, {
                  method: 'PUT'
                });
                if (!response.ok) {
                  gaEventTracker('url-redirect-error')
                  throw new Error('Failed to send click event');
                }
              } catch (error) {
                console.error('Error:', error);
              }
            };
  useEffect(() => {
    // Function to perform GET request with short URL from URL path
    const fetchData = async () => {
      try {
        // Get the short URL ID from the URL path
        const shortUrlId = window.location.pathname.split('/').pop();
        const response = await fetch(`${apiBaseUrl}/url/${shortUrlId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setOriginalUrl(data.original_url); // Update state with the retrieved original URL
        setUrlId(data.id)
        gaEventTracker('url-fetch-success')
      } catch (error) {
        setError('Failed to fetch');
      }
    };



    fetchData(); // Call the function when component mounts
  }, []); // Empty dependency array ensures this effect runs only once, when component mounts

  // Redirect user to original URL if available
  useEffect(() => {
      if (originalUrl) {
        const fullUrl = addProtocol(originalUrl);
        sendClickEvent();
        window.location.replace(fullUrl);
      }
    }, [originalUrl]);

    const addProtocol = (url) => {
      // Check if the URL already has a protocol
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // If not, assume it's a relative URL and prepend "http://"
        return 'http://' + url;
      }
      return url;
    };

  return (
    <div>
      <h1>Redirecting...</h1>
      {error && <p>{error}</p>}
    </div>
  );
}

export default RedirectPage;
