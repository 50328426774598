import React, { useState, useEffect } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { FaSun, FaMoon } from "react-icons/fa";


export default function DarkModeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.style.setProperty('--color-text', '#ffffff');
      root.style.setProperty('--color-background', '#252524');
    } else {
      root.style.setProperty('--color-text', '#00000');
      root.style.setProperty('--color-background', '#ffffff');
    }
  }, [isDarkMode]);

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <label>
      <Toggle
        defaultChecked={isDarkMode}
        icons={{
          checked: <FaSun color='white' />,
          unchecked: <FaMoon color='white'/>,
        }}
    
        onChange={handleToggle}
      />
    </label>
  );
}
