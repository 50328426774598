import React, { useState } from 'react';
import '../App.css';
import useAnalyticsEventTracker from '../analytics/useAnalyticsEventTracker';


const QrPage = ({ apiBaseUrl }) => {
  const [inputValue, setInputValue] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  document.title = "QR code";

  const gaEventTracker = useAnalyticsEventTracker('QR page');


  const handleSaveButtonClick = () => {
    // If imageURL is not empty, trigger download
    if (imageURL) {
      const a = document.createElement('a');
      a.href = imageURL;
      a.download = 'QRCode.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    const postData = {
      original_url: inputValue
    };

    fetch(`${apiBaseUrl}/qr/?qr_input=${encodeURIComponent(inputValue)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (!response.ok) {
          gaEventTracker('fetch-error')
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the Blob
        const imageURL = URL.createObjectURL(blob);
        setImageURL(imageURL);
        gaEventTracker('qr-success')
      })
      .catch(error => {
        setErrorMessage('Error creating QR code');
        console.error('Error:', error);
        gaEventTracker('qr-create-error')
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Create QR code</h1>
        <div className="centered">
          <input
            className="text-field"
            type="text"
            placeholder="Link or Data for QR"
            value={inputValue}
            onChange={handleInputChange}
          />
          <button className="button" onClick={handleButtonClick}>Create QR</button>
        </div>
        {errorMessage && (
          <p className="error-message">{errorMessage}</p>
        )}
        {imageURL && (
          <div>
            <div>
              <img src={imageURL} alt="QR Code" />
            </div>
            <div>
              <button className="button" onClick={handleSaveButtonClick}>Download QR</button>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default QrPage;
