// NavigationBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import DarkModeToggle from './darkModeToggle'

const NavigationBar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">URL Shortener</Link>
        </li>
        <li className="nav-item">
          <Link to="/qr" className="nav-link">QR Code Generator</Link>
        </li>
        <li className="nav-item-right">
          <DarkModeToggle />
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
